@use './../shared.scss';

/* :root {
  --dialog-btn-text-color: #ffffff;
  --dialog-btn-bg-color: #{shared.$theme-color-1};
} */

.login-dialog {
  .MuiDialog-paper {
    width: 600px;
    max-width: 80vw;
    .MuiDialogTitle-root {
      padding: 24px;
      text-align: center;
      .close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        color: #ffffff;
        color: var(--dialog-btn-text-color);
        background: shared.$theme-color-1;
        // background: var(--dialog-btn-bg-color);
        border: 0;
        cursor: pointer;
        .icon {
          width: 30px;
          height: 30px;
        }
      }
    }
    .MuiDialogContent-root {
      padding: 0 20px 20px 20px;
      text-align: center;
      .MuiDialogContentText-root {
        color: #000000;
        font-family: shared.$default-font-family;
      }
      white-space: pre-wrap;
    }
  }
}
