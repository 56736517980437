.chart-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.chart-wrapper {
  width: 34%;
  min-width: 420px;
  margin: 10px;
  box-sizing: border-box;
}

.chart-wrapper canvas {
  width: 100%;
  height: 300px;
}

.rectangle {
  width: 326px;
  height: 200px;
  border: 2px solid #0c6251;
  margin: 20px 10px;
  padding: 0px 20px;
  border-radius: 10px;
  text-align: left;
  overflow: auto;
  p {
    margin: 2px 0px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-size: 14px;
  }
  h4 {
    font-family: 'Francois One', sans-serif;
    font-style: normal;
    font-size: 20px;
    margin: 20px 0px;
  }
}
