@use './../shared.scss';

:root {
  --theme-background-color: #{shared.$theme-color-2};
  --theme-active-tab-text-color: #ffffff;
  --theme-tab-text-color: rgba(255, 255, 255, 0.6);
  --theme-text-color: #ffffff;
  --theme-date-bg-color: #ffffff;
  --theme-date-text-color: #{shared.$theme-color-2};
  --theme-qa-submit-btn-bg-color: #{shared.$theme-color-1};
  --theme-qa-submit-btn-text-color: #ffffff;
  --theme-tab-brightness: 0.8;
  --event-info-max-height-mobile: 300px;
}

@keyframes blink-animation {
  0% {
    color: #ffffff;
  }
  50% {
    color: transparent;
  }
  100% {
    color: #ffffff;
  }
}

.event-page {
  margin: 0 auto;
  .event-container {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 0 20px;
    .overlay {
      display: flex;
      align-items: center;
      padding: 20px;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      color: #ffffff;
      .overlay-icon {
        margin-right: 20px;
      }
      .overlay-message {
        text-align: left;
        .btn-wrapper {
          margin-top: 10px;
          button {
            background-color: #5f5f5f;
            color: #ffffff;
            font-size: 16px;
            appearance: none;
            cursor: pointer;
            border-radius: 2px;
            border: 0;
            padding: 2px 5px;
            &:hover {
              background-color: #4a4a4a;
            }
          }
        }
      }
    }
    .player-container {
      width: 100%;
    }
    .side-panel {
      width: 100%;
      background-color: shared.$theme-color-2;
      background-color: var(--theme-background-color);
      flex: auto;
      display: flex;
      flex-direction: column;
      .side-panel-tabs {
        background-color: shared.$theme-color-2;
        background-color: var(--theme-tab-background-color, var(--theme-background-color));
        filter: brightness(var(--theme-tab-brightness));
        .MuiTabs-indicator {
          height: 5px;
          background-color: #ffffff;
          background-color: var(--theme-active-tab-text-color);
        }
        .side-panel-tab {
          font: unset;
          font-weight: 400;
          font-size: 14px;
          font-family: shared.$default-font-family;
          text-transform: none;
          letter-spacing: normal;
          min-height: 50px;
          min-width: 50px;
          line-height: 0.5;
          height: 5px;
          padding: 5px 15px 8px 15px;
          color: rgba(255, 255, 255, 0.6);
          color: var(--theme-tab-text-color);
          .MuiTab-iconWrapper {
            margin-bottom: 2px;
            width: 20px;
            height: 20px;
          }
          &.Mui-selected {
            color: #ffffff;
            color: var(--theme-active-tab-text-color);
          }
        }
        .audio-selector-wrapper {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          color: var(--theme-active-tab-text-color);
          &::before {
            content: '\E050\E5C5';
            font-size: 20px;
            display: block;
            position: absolute;
            top: 8px;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            pointer-events: none;
            font-family: Material Icons;
            font-weight: 400;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-transform: none;
            white-space: nowrap;
            word-wrap: normal;
            direction: ltr;
            -webkit-font-smoothing: antialiased;
            text-rendering: optimizeLegibility;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-feature-settings: 'liga';
            font-feature-settings: 'liga';
          }
          .audio-selector {
            height: 100%;
            padding: 18px 10px 0 10px;
            border: 0;
            background-color: shared.$theme-color-2;
            background-color: var(--theme-background-color);
            text-align: center;
            color: #ffffff;
            color: var(--theme-active-tab-text-color);
            font-weight: 400;
            font-size: 14px;
            font-family: shared.$default-font-family;
            text-transform: capitalize;
            cursor: pointer;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
          }
        }
      }
      .tab-content {
        flex-grow: 1;
        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 10px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
          background: #888888;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #555555;
        }
        .event-info {
          padding: 10px;
          text-align: left;
          max-height: 300px;
          max-height: var(--event-info-max-height-mobile);
          .time {
            display: flex;
            align-items: center;
            padding: 10px;
            font-size: 14px;
            // line-height: shared.$h4-line-height;
            color: #ffffff;
            color: var(--theme-text-color);
            .start-date,
            .end-date {
              width: 40px;
              height: 40px;
              border-radius: 25px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              line-height: 1.1;
              background-color: #ffffff;
              background-color: var(--theme-date-bg-color);
              .month {
                display: block;
                color: shared.$theme-color-2;
                color: var(--theme-date-text-color);
              }
              .day {
                display: block;
                font-weight: bold;
                color: shared.$theme-color-2;
                color: var(--theme-date-text-color);
              }
            }
            .end-date {
              margin-left: 10px;
            }
            .start-time {
              margin: 0 10px;
            }
            .end-time {
              margin: 0 0 0 10px;
            }
          }
          .title {
            padding: 10px;
            font-size: shared.$h4;
            font-weight: bold;
            // line-height: shared.$h2-line-height;
            color: #ffffff;
            color: var(--theme-text-color);
            white-space: pre-wrap;
          }
          .description {
            padding: 10px;
            font-size: shared.$h4;
            // line-height: shared.$h4-line-height;
            color: #ffffff;
            color: var(--theme-text-color);
            white-space: pre-wrap;
            img {
              max-width: 100%;
            }
          }
          .question-container {
            background-color: #ffffff;
            padding: 10px;
            width: 100%;
            height: 130px;
            border-radius: 5px;

            .question-area {
              width: 100%;
              height: 60px;
              outline: none;
              border: none;
              resize: none;
              background-color: transparent;
              overflow-x: hidden;
              font-size: shared.$h4;
            }
            .submit-btn {
              text-align: center;
              font-size: 15px;
              width: 100%;
              height: 45px;
              vertical-align: middle;
              outline: none;
              border: none;
              cursor: pointer;
              border-radius: 5px;
              padding: 12px 0;
              color: #ffffff;
              color: var(--theme-qa-submit-btn-text-color);
              background-color: shared.$theme-color-1;
              background-color: var(--theme-qa-submit-btn-bg-color);
              .MuiCircularProgress-root {
                color: #ffffff;
                color: var(--theme-qa-submit-btn-text-color);
              }
            }
          }
          .audio-wrapper {
            display: flex;
            flex-grow: 1;
            align-items: flex-end;
            padding: 10px;
            .MuiInputBase-root {
              &::before {
                border: none;
              }
              &::after {
                border: none;
              }
            }
          }
        }
        .qa {
          height: 300px;
          .tab-content {
            height: 100%;
            overflow: hidden;
          }
        }
        .chatroom {
          .tab-content {
            display: flex;
            height: 500px;
          }
          .chatroom-iframe {
            width: 100%;
          }
        }
        .polling {
          .tab-content {
            display: flex;
            height: 100%;
          }
          &.slido {
            height: 300px;
            .tab-content {
              height: 100%;
              overflow: hidden;
            }
          }
          .polling-iframe {
            width: 100%;
          }
        }
      }
    }
  }
  .event-status {
    text-align: left;
    padding: 10px 20px;
    .live-mark {
      display: inline-flex;
      padding: 4px 4px;
      border-radius: 4px;
      background-color: red;
      color: #ffffff;
      line-height: 1;
      .dot {
        animation: blink-animation 2s ease-in-out infinite;
        padding-right: 2px;
      }
    }
    .age {
      padding: 2px 10px;
      margin: 0 10px;
      line-height: 1;
      border-left: 1px solid black;
    }
  }
  .hlsjs-player-stat {
    padding: 20px;
  }
  .event-border {
    border-bottom: 1px solid shared.$grey-color-2;
  }
  .polling-wrapper {
    padding: 20px;
    text-align: left;
    .polling-iframe {
      width: 100%;
      max-width: 700px;
    }
  }
  .detail-description {
    text-align: left;
    padding: 20px;
    font-size: shared.$h4;
    line-height: shared.$h4-line-height;
    white-space: pre-wrap;
    img {
      max-width: 100%;
    }
  }
}

@include shared.tablet {
  .event-page {
    max-width: shared.$max-width;
    .event-container {
      padding: 20px 25px 0px 25px;
      flex-direction: row;
      .player-container {
        width: calc(2 / 3 * 100%);
      }
      .side-panel {
        width: calc(1 / 3 * 100%);
        .tab-content {
          flex-basis: 0px;
          .event-info {
            max-height: none;
            height: 100%;
            .tab-content {
              display: flex;
              flex-direction: column;
              height: 100%;
            }
            .title {
              font-size: shared.$h2;
            }
            .question-container {
              margin-top: auto;
            }
          }
          .qa {
            height: 100%;
          }
          .chatroom {
            height: 100%;
            .tab-content {
              display: flex;
              height: 100%;
            }
            .chatroom-iframe {
              min-height: 100%;
            }
          }
          .polling {
            height: 100%;
            &.slido {
              height: 100%;
            }
            .polling-iframe {
              min-height: 100%;
            }
          }
        }
      }
    }
    .event-status {
      padding: 10px 25px;
    }
    .detail-description {
      padding: 20px 25px 20px 25px;
    }
  }
}
