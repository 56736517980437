@mixin breakpoint($min-width) {
  @media (min-width: #{$min-width}) {
    @content;
  }
}

@mixin large-mobile() {
  @media (min-width: #{$sd-width}) {
    @content;
  }
}

@mixin tablet() {
  @media (min-width: #{$md-width}) {
    @content;
  }
}

@mixin desktop() {
  @media (min-width: #{$ld-width}) {
    @content;
  }
}
