@use './../shared.scss';

.embed-page {
  min-height: 0;
  height: 100vh;
  height: var(--app-height);
  .event-container {
    height: 100%;
    .active-header {
      position: absolute;
      z-index: 1;
      width: 100%;
      padding: 10px;
      display: flex;
      justify-content: flex-end;
      .audio-selector-wrapper {
        display: flex;
        align-items: center;
        .audio-selector {
          height: 40px;
          outline: none;
          border: none;
          cursor: pointer;
          border-radius: 5px;
          padding: 8px 40px 8px 10px;
          font-size: 16px;
          color: #ffffff;
          background-color: rgba(160, 160, 160, 0.7);
          text-transform: capitalize;
          appearance: none;
          &::-ms-expand {
            display: none;
          }
        }
        &:after {
          content: '';
          display: inline-block;
          position: absolute;
          pointer-events: none;
          width: 0;
          height: 0;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 8px solid #ffffff;
          right: 20px;
        }
      }
    }
  }
}
