.latency-label {
  display: inline;
  border-top: 10px;
  vertical-align: -1px;
  margin-left: -10px;
  p {
    display: inline;
    font-size: 14px;
  }
}
