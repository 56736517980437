@use 'shared.scss';

@import '~normalize-scss/sass/normalize';
@import 'typography';

body {
  margin: 0;
  font-family: shared.$default-font-family;
}

button,
input,
select,
textarea {
  font-family: shared.$default-font-family;
}

* {
  box-sizing: border-box;
}
