@use './shared.scss';

:root {
  --app-height: 100vh;
  --header-background: #{shared.$header-color};
  --header-locale-color: #{shared.$grey-color-7};
  --header-active-locale-color: #{shared.$theme-color-1};
  --header-height: #{shared.$header-height};
  --header-height-mobile: #{shared.$header-height-mobile};
  --footer-background: #{shared.$footer-color};
  --footer-text-color: #ffffff;
  --logo-height: 56px;
  --logo-height-mobile: 49px;
}

.app {
  text-align: center;
  .app-header {
    height: shared.$header-height-mobile;
    height: var(--header-height-mobile);
    background: shared.$header-color;
    background: var(--header-background);
    -webkit-appearance: none;
    box-shadow: 0 5px 10px shared.$grey-color-2;
    display: flex;
    justify-content: center;
    align-items: center;
    .header-wrapper {
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      &.restrict {
        max-width: shared.$max-width;
      }
    }
    .logo-wrapper,
    .custom-logo-wrapper {
      display: flex;
      height: 100%;
      width: auto;
      align-items: center;
      .app-logo {
        height: 49px;
        height: var(--logo-height-mobile);
        pointer-events: none;
      }
    }
    .custom-logo-wrapper {
      .desktop {
        display: none;
      }
    }
    .header-function-container {
      display: flex;
      .locale-selector {
        .locale {
          color: var(--header-locale-color);
          &.active {
            color: var(--header-active-locale-color);
          }
        }
      }
      .function {
        padding: 1px 2px;
        border: 0;
        background-color: transparent;
        cursor: pointer;
        color: shared.$grey-color-7;
        color: var(--header-locale-color);
        font-size: 14px;
        line-height: shared.$h4-line-height;
      }
    }
  }
  main {
    min-height: calc(100vh - shared.$header-height-mobile - shared.$footer-height-mobile);
    min-height: calc(
      var(--app-height) - var(--header-height-mobile) - shared.$footer-height-mobile
    );
  }
  .app-footer {
    height: shared.$footer-height-mobile;
    background: shared.$footer-color;
    background: var(--footer-background);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .link {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      .logo {
        height: 26px;
        margin-right: 10px;
      }
      .text {
        color: #ffffff;
        color: var(--footer-text-color);
        font-size: 14px;
        // font-size: shared.$h5;
        // line-height: shared.$h5-line-height;
      }
    }
    .icp {
      align-items: center;
      display: flex;
      a {
        align-items: center;
        color: #ffffff;
        color: var(--footer-text-color);
        font-size: 12px;
        display: flex;
        padding-left: 5px;
        img {
          vertical-align: middle;
        }
      }
    }
  }
}

@include shared.tablet {
  .app {
    .app-header {
      height: shared.$header-height;
      height: var(--header-height);
      .header-wrapper {
        padding: 0 25px;
      }
      .logo-wrapper,
      .custom-logo-wrapper {
        .app-logo {
          height: 56px;
          height: var(--logo-height);
        }
      }
      .custom-logo-wrapper {
        .desktop {
          display: block;
        }
        .mobile {
          display: none;
        }
      }
      .header-function-container {
        .function {
          padding: 1px 6px;
          font-size: shared.$h4;
        }
      }
    }
    main {
      min-height: calc(100vh - shared.$header-height - shared.$footer-height);
      min-height: calc(var(--app-height) - var(--header-height) - shared.$footer-height);
    }
    .app-footer {
      height: shared.$footer-height;
    }
  }
}
