$theme-color-1: #3fbb9b;
$theme-color-2: #0c6251;

$h1: 32px;
$h2: 24px;
$h3: 18px;
$h4: 16px;
$h5: 12px;
$h6: 10px;

$h1-line-height: 100%;
$h2-line-height: 150%;
$h3-line-height: 28px;
$h4-line-height: 150%;
$h5-line-height: 150%;
$h6-line-height: 16px;

$grey-color-0: #f9f9f9;
$grey-color-1: #ececec;
$grey-color-2: #dddddd;
$grey-color-3: #cccccc;
$grey-color-4: #aaaaaa;
$grey-color-5: #999999;
$grey-color-6: #777777;
$grey-color-7: #666666;
$grey-color-8: #444444;
$grey-color-9: #333333;

$blue-color: #00a1ff;
$red-color: #f62c73;

$header-color: #ffffff;
$footer-color: $theme-color-2;

$sd-width: 600px;
$md-width: 768px;
$ld-width: 992px;

$max-width: 1322px;

$header-height: 80px;
$header-height-mobile: 70px;
$footer-height: 60px;
$footer-height-mobile: 60px;

$default-font-family: 'Lato', 'Microsoft JhengHei', 'Helvetica', 'arial';
