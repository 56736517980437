@use './../shared.scss';

:root {
  --dialog-btn-text-color: #ffffff;
  --dialog-btn-bg-color: #{shared.$theme-color-1};
}

.message-dialog {
  .MuiDialog-paper {
    width: 400px;
    max-width: 80vw;
    .MuiDialogTitle-root {
      .close-btn {
        position: absolute;
        top: 0;
        right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        color: #ffffff;
        color: var(--dialog-btn-text-color);
        background: shared.$theme-color-1;
        background: var(--dialog-btn-bg-color);
        border: 0;
        cursor: pointer;
        .icon {
          width: 16px;
          height: 16px;
        }
      }
    }
    .MuiDialogContent-root {
      padding: 0 20px;
      text-align: center;
      .MuiDialogContentText-root {
        color: #000000;
        font-family: shared.$default-font-family;
      }
      white-space: pre-wrap;
    }
    .MuiDialogActions-root {
      padding: 20px;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 6px;
        font-size: shared.$h4;
        line-height: shared.$h4-line-height;
        color: #ffffff;
        color: var(--dialog-btn-text-color);
        background: shared.$theme-color-1;
        background: var(--dialog-btn-bg-color);
        border: 0;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
}
