@use './../shared.scss';

.theo-primary-color,
.vjs-selected {
  color: shared.$theme-color-1 !important;
}

.theo-primary-background {
  color: #000000 !important;
  background-color: shared.$theme-color-1 !important;
}

.theo-secondary-color {
  color: #ffffff !important;
}

.theo-secondary-background {
  color: #000000 !important;
  background-color: #ffffff !important;
}

.theo-tertiary-color {
  color: #000000 !important;
}

.theo-tertiary-background {
  color: #ffffff !important;
  background-color: #000000 !important;
}

.theoplayer-container {
  @import 'theoplayer/ui.css';
  &.embed {
    padding-top: 0;
    height: 100%;
    overflow: hidden;
  }
  &.theoplayer-skin {
    &:not(.subtitled) {
      .theo-audio-track-control-button {
        display: none !important;
      }
    }
  }
}
