@use './../shared.scss';

.locale-selector {
  height: 100%;
  display: flex;
  .locale {
    padding: 1px 2px;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    color: shared.$grey-color-7;
    font-size: 14px;
    line-height: shared.$h4-line-height;
    &.active {
      color: shared.$theme-color-1;
      cursor: auto;
    }
  }
}

@include shared.tablet {
  .locale-selector {
    .locale {
      padding: 1px 6px;
      font-size: shared.$h4;
    }
  }
}
