@use './../shared.scss';

:root {
  --pc-background-color: #ffffff;
  --pc-background-image: url('/images/bg_banner.png');
  --pc-background-image-mobile: url('/images/bg_banner_mobile.png');
  --pc-background-position-x: 0%;
  --pc-background-position-x-tablet: 0%;
  --pc-background-position-x-mobile: 0%;
  --pc-background-position-y: 0%;
  --pc-background-position-y-tablet: 0%;
  --pc-background-position-y-mobile: 0%;
  --pc-background-size: cover;
  --pc-background-size-mobile: cover;
  --pc-logo-height: 80px;
  --pc-logo-height-mobile: 49px;
  --pc-input-text-color: #{shared.$theme-color-1};
  --pc-submit-btn-text-color: #ffffff;
  --pc-submit-btn-bg-color: #a9a8b3;
  --pc-submit-btn-active-text-color: #ffffff;
  --pc-submit-btn-active-bg-color: #{shared.$theme-color-1};
  --pc-label-text-color: #ffffff;
  --pc-title-text-color: #ffffff;
  --pc-remarks-text-color: #ffffff;
  --pc-footer-text-color: #ffffff;
  --pc-content-padding: 10vh 80px 20px 80px;
  --pc-content-max-width: 50vw;
  --pc-input-width: 100%;
  --pc-footer-padding: 0 80px;
  --pc-header-height: 80px;
  --pc-header-height-mobile: 70px;
  --pc-header-locale-color: #{shared.$grey-color-7};
  --pc-header-active-locale-color: #{shared.$theme-color-1};
  --pc-header-background-color: transparent;
  --pc-header-background-color-mobile: transparent;
  --pc-content-background-color: transparent;
  --pc-content-background-color-mobile: transparent;
  --pc-footer-background-color: transparent;
  --pc-footer-background-color-mobile: transparent;
}

.passcode-page {
  overflow: auto;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #ffffff;
  background-color: var(--pc-background-color);
  background-image: url('/images/bg_banner_mobile.png');
  background-image: var(--pc-background-image-mobile);
  background-repeat: no-repeat;
  background-size: cover;
  background-size: var(--pc-background-size-mobile);
  background-position-x: 0%;
  background-position-x: var(--pc-background-position-x-mobile);
  background-position-y: 0%;
  background-position-y: var(--pc-background-position-y-mobile);
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    height: var(--pc-header-height-mobile);
    background-color: transparent;
    background-color: var(--pc-header-background-color-mobile);
    padding: 0 15px;
    .logo-wrapper {
      display: none;
      &.mobile {
        display: block;
      }
      .logo {
        height: 49px;
        height: var(--pc-logo-height-mobile);
      }
    }
    .locale-selector {
      margin-left: auto;
      .locale {
        color: var(--pc-header-locale-color);
        &.active {
          color: var(--pc-header-active-locale-color);
        }
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 0;
    min-height: calc(100vh - 70px - 60px);
    min-height: calc(var(--app-height) - var(--pc-header-height-mobile) - 60px);
    line-height: 1.15;
    .mobile-banner {
      img {
        width: 100%;
      }
    }
    .mobile-bottom-banner {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      flex-grow: 1;
      img {
        width: 100%;
      }
    }
    .content-container {
      background-color: transparent;
      background-color: var(--pc-content-background-color-mobile);
      padding: 20px 15px;
    }
    .logo-wrapper {
      display: none;
    }
    .title {
      color: #ffffff;
      color: var(--pc-title-text-color);
      font-size: 30px;
      font-weight: 700;
      margin: 0px 0px 20px 0px;
      white-space: pre-wrap;
    }
    .input-label {
      color: #ffffff;
      color: var(--pc-label-text-color);
      display: block;
      font-size: shared.$h4;
      padding-bottom: 20px;
      &.text-only {
        padding-bottom: 0px;
      }
    }
    .input-row {
      margin: 20px 0px;
      input,
      select {
        width: 100%;
        padding: 6px 12px;
        color: shared.$theme-color-1;
        color: var(--pc-input-text-color);
        -webkit-appearance: none;
        box-shadow: 0 0 15px 0 rgba(35, 31, 32, 0.5);
        font-size: shared.$h2;
        line-height: shared.$h2-line-height;
        outline: none;
        border: none;
        border-radius: 5px;
        line-height: normal;
        font-size: 36px;
        &.capital {
          text-transform: uppercase;
          letter-spacing: 5px;
        }
      }
      select {
        overflow-x: hidden;
        text-overflow: ellipsis;
        padding: 13px 12px;
        font-size: 24px;
      }
      .select-wrapper {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: 22px;
          right: 10px;
          width: 0;
          height: 0;
          border-left: 12px solid transparent;
          border-right: 12px solid transparent;
          border-top: 16px solid shared.$theme-color-1;
          border-top: 16px solid var(--pc-input-text-color);
        }
      }
      .checkbox-container {
        width: 100%;
        position: relative;
      }
      input[type='checkbox'] {
        width: 22px;
        height: 22px;
        opacity: 0;
        margin: 0;
        position: absolute;
        cursor: pointer;
        + label {
          cursor: pointer;
          padding-bottom: 0px;
          &:before {
            content: '';
            display: inline-block;
            vertical-align: top;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            border-radius: 3px;
            border: 1px solid #dcdcdc;
            -webkit-appearance: none;
            box-shadow: 0 0 15px 0 rgba(35, 31, 32, 0.5);
            background: #fff;
            margin-right: 10px;
          }
          span {
            display: inline-block;
            vertical-align: top;
            width: calc(100% - 60px);
          }
          &:after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 4px;
            left: 3px;
            width: 14px;
            height: 6px;
            border: 2px solid transparent;
            border-top: none;
            border-right: none;
            background: transparent;
            transform: rotate(-45deg);
          }
        }
        &:checked {
          + label {
            &:after {
              border: 2px solid shared.$theme-color-1;
              border: 2px solid var(--pc-input-text-color);
              border-top: none;
              border-right: none;
            }
          }
        }
      }
      &.top {
        margin: 0px 0px 20px 0px;
      }
    }
    .submit-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 8px 6px;
      font-size: 14px;
      line-height: shared.$h4-line-height;
      color: #ffffff;
      color: var(--pc-submit-btn-text-color);
      background: #a9a8b3;
      background: var(--pc-submit-btn-bg-color);
      border: 0;
      border-radius: 5px;
      -webkit-appearance: none;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
      margin-top: 20px;
      &.active {
        color: #ffffff;
        color: var(--pc-submit-btn-active-text-color);
        background: shared.$theme-color-1;
        background: var(--pc-submit-btn-active-bg-color);
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
        cursor: pointer;
      }
      .MuiCircularProgress-root {
        color: #ffffff;
        color: var(--pc-submit-btn-active-text-color);
      }
    }
    .remarks {
      color: #ffffff;
      color: var(--pc-remarks-text-color);
      font-size: 14px;
      .remark {
        margin: 20px 0 0 0;
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 60px;
    font-size: shared.$h5;
    background-color: transparent;
    background-color: var(--pc-footer-background-color-mobile);
    .link {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      color: var(--pc-footer-text-color);
      text-decoration: none;
    }
    .logo {
      height: 34px;
      margin-right: 10px;
    }
    .icp {
      align-items: center;
      display: flex;
      a {
        align-items: center;
        color: #ffffff;
        color: var(--pc-footer-text-color);
        font-size: 12px;
        display: flex;
        padding-left: 5px;
        img {
          vertical-align: middle;
        }
      }
    }
  }

  &.tablet {
    background-image: url('/images/bg_banner.png');
    background-image: var(--pc-background-image);
    background-size: cover;
    background-size: var(--pc-background-size);
    background-position-x: 0%;
    background-position-x: var(--pc-background-position-x-tablet);
    background-position-y: 0%;
    background-position-y: var(--pc-background-position-y-tablet);
    .header {
      height: 80px;
      height: var(--pc-header-height);
      background-color: transparent;
      background-color: var(--pc-header-background-color);
      .logo-wrapper {
        display: block;
        .logo {
          height: 80px;
          height: var(--pc-logo-height);
        }
        &.mobile {
          display: none;
        }
      }
    }
    .content {
      padding: 10vh 80px 20px 80px;
      padding: var(--pc-content-padding);
      max-width: 50vw;
      max-width: var(--pc-content-max-width);
      min-height: calc(100vh - 80px - 60px);
      min-height: calc(var(--app-height) - var(--pc-header-height) - 60px);
      .mobile-banner {
        display: none;
      }
      .mobile-bottom-banner {
        display: none;
      }
      .content-container {
        background-color: transparent;
        background-color: var(--pc-content-background-color);
        padding: 0px;
      }
      .logo-wrapper {
        display: block;
        .logo {
          height: 80px;
          height: var(--pc-logo-height);
        }
      }
      .title {
        margin: 20px 0px;
      }
      .submit-btn {
        width: 150px;
      }
      .input-row {
        input,
        select {
          width: 100%;
          width: var(--pc-input-width);
        }
        .checkbox-container {
          width: 100%;
          width: var(--pc-input-width);
        }
      }
      .input-container {
        &.inline {
          position: relative;
          background-color: #ffffff;
          border-radius: 5px;
          padding: 4px 8px;
          width: 100%;
          width: var(--pc-input-width);
          -webkit-appearance: none;
          box-shadow: 0 0 15px 0 rgba(35, 31, 32, 0.5);
          .input-row {
            margin: 0;
          }
          input {
            width: calc(100% - 162px);
            box-shadow: none;
            padding: 0;
          }
          .submit-btn {
            position: absolute;
            right: 8px;
            top: calc((100% - 37px) / 2);
            margin: 0;
          }
        }
      }
    }
    .footer {
      flex-direction: row;
      justify-content: flex-start;
      padding: 0px 80px;
      padding: var(--pc-footer-padding);
      background-color: transparent;
      background-color: var(--pc-footer-background-color);
      .icp {
        a {
          padding-left: 10px;
        }
      }
    }
  }

  &.desktop {
    background-position-x: 0%;
    background-position-x: var(--pc-background-position-x);
    background-position-y: 0%;
    background-position-y: var(--pc-background-position-y);
  }
}
